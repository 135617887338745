import React from 'react'

import './style/trademark-disputes.scss'

const TrademarkDisputes = () => (
  <div className="row-wrapper copy-right trademark-disputes">
    <div className="container">
      <section className="policy-agree">
        <div className="row">
          <div className="col-sm-12">
            <h2>Domain Name Dispute Policy</h2>
          </div>
        </div>
      </section>
    </div>
    <div className="container tos">
      <div className="row">
        <div className="col-xs-12">
          <ul className="legal">
            <p></p><p>1. Newfold Digital, Inc. ("Newfold") is not responsible for resolving any domain disputes.
            If you have a complaint about the issue of ownership or wording of the domain name itself (as
            opposed to the content hosted on the associated website), that matter will need to be taken up
            with the domain name owner directly, or should be filed in a UDRP or court proceeding. Our
            internal policies, as well as ICANN regulations, prevent us from acting as the arbiter of domain
            name disputes; we are only able to assist with complaints regarding the content of a hosted
            website (as opposed to the wording of the domain name registered).</p>

            <p>2. ICANN's Uniform Domain Name Dispute Resolution Policy (the "UDRP") is a method for resolving domain
            disputes relating to abusive domain registrations (for example, cybersquatting or trademark
            infringement). Please refer to <a href='http://www.icann.org/en/help/dndr/udrp'>http://www.icann.org/en/help/dndr/udrp</a> for more information.</p>

            <p>3. If you have a complaint about the use of your trademark on a website hosted by us, please use the Report Member Violations form available <a href='https://nam12.safelinks.protection.outlook.com/?url=https%3A%2F%2Fnewfold.com%2Fabuse&data=05%7C02%7Cgrace.martin%40newfold.com%7Cc18c888307a04d948ee108dd4adcfdc9%7Cd3008fd40d20418bbf304e70d910c727%7C0%7C0%7C638749035182100285%7CUnknown%7CTWFpbGZsb3d8eyJFbXB0eU1hcGkiOnRydWUsIlYiOiIwLjAuMDAwMCIsIlAiOiJXaW4zMiIsIkFOIjoiTWFpbCIsIldUIjoyfQ%3D%3D%7C0%7C%7C%7C&sdata=dn1erfHNcU%2FkgdP8ZPJNaYDAKQox%2BphpAKD4PIbPW98%3D&reserved=0'>here</a>.</p>

            <p>This file was last modified February 2025</p>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default TrademarkDisputes
